<template>
  <div>
    <v-tooltip
      v-if="
        usersStore.authUserType !== 4 &&
          usersStore.authUserType !== 7 &&
          section === 2
      "
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="openEntry(usersStore.userProfile.id)">
          <v-icon
            :color="$vuetify.theme.dark === false ? 'secondary' : 'white'"
          >
            fal fa-pencil-alt
          </v-icon>
        </v-btn>
      </template>
      <span>Edit Profile</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      scrollable
      max-width="1200"
      :hide-overlay="section === 1 ? true : false"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-card-title class="text-h5 secondary--text">
          Profile Details
          <v-spacer />
          <v-btn text rounded outlined @click="cancelEntry()">
            <v-icon small left> fal fa-times </v-icon>Cancel
          </v-btn>
        </v-card-title>
        <v-card-title v-if="section === 1">
          <v-row dense>
            <v-col cols="12" sm="12" md="12" class="text-center">
              <span class="text-h5 primary--text">Welcome to the T-2 DOP</span
              ><br />
              <span class="text-h6 nsfgrey--text"
                >This profile page must be completed and the Submit button
                selected in order to enter the main content area of the T-2
                DOP.</span
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <span class="caption error--text">* Required Fields</span>
                </v-col>
              </v-row>

              <v-row v-if="flag_user_type === 1 || flag_user_type === 2" dense>
                <!--Title-->
                <v-col cols="12" sm="12" md="3">
                  <!--Title-->
                  <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7 ||
                          (usersStore.profile.flag_user_type === 2 &&
                            section > 1) ||
                          (usersStore.profile.flag_user_type === 1 &&
                            section > 1)
                    "
                    :display-string="usersStore.profile.title"
                    :label="'Title'"
                  />
                  <v-autocomplete
                    v-else
                    v-model="title"
                    :items="valueListsStore.titles"
                    label="Title *"
                    placeholder=" "
                    item-text="value"
                    item-value="value"
                    :clearable="
                      readonly !== true &&
                        usersStore.profile.flag_user_type !== 2
                    "
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.title
                    "
                  />
                </v-col>

                <!--Other Title-->
                <v-col v-if="title === 'Other'" cols="12" sm="12" md="3">
                  <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7 ||
                          (usersStore.profile.flag_user_type === 2 &&
                            section > 1) ||
                          (usersStore.profile.flag_user_type === 1 &&
                            section > 1)
                    "
                    :display-string="usersStore.profile.title_other"
                    :label="'Specify Other Title'"
                  />
                  <v-text-field
                    v-else
                    v-model="title_other"
                    label="Specify Other Title *"
                    placeholder=" "
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.title_other
                    "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Classification-->
                <v-col
                  v-if="
                    [1, 4, 5].includes(usersStore.authUser.flag_type) ||
                      usersStore.authUser.id !== usersStore.profile.id_user
                  "
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 3 ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7 ||
                          mode === 2
                    "
                    :display-string="usersStore.profile.classification"
                    :label="'Classification'"
                  />
                  <v-autocomplete
                    v-else
                    v-model="classification"
                    :items="
                      flag_user_type === 1 || flag_user_type === 2
                        ? valueListsStore.classificationsResearchers
                        : valueListsStore.classificationsTrainees
                    "
                    label="Classification *"
                    placeholder=" "
                    item-text="value"
                    item-value="value"
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.classification
                    "
                  />
                </v-col>

                <!--Other Classification-->
                <v-col
                  v-if="classification === 'Other'"
                  cols="12"
                  sm="12"
                  md="3"
                >
                  <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 3 ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7 ||
                          mode === 2
                    "
                    :display-string="usersStore.profile.classification_other"
                    :label="'Specify Other Classification'"
                  />
                  <v-text-field
                    v-else
                    v-model="classification_other"
                    label="Specify Other Classification *"
                    placeholder=" "
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.classification_other
                    "
                  />
                </v-col>

                <!--ORCID ID-->
                <v-col cols="12" sm="12" md="3">
                  <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7
                    "
                    :display-string="usersStore.profile.orcid_id"
                    :label="'ORCID ID'"
                  />
                  <v-text-field
                    v-else
                    v-model="orcid_id"
                    label="ORCID ID"
                    placeholder=" "
                  />
                </v-col>

                <!--Phone-->
                <v-col cols="12" sm="12" md="3">
                  <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7
                    "
                    :display-string="usersStore.profile.phone"
                    :label="'Phone'"
                  />
                  <v-text-field
                    v-else
                    v-model="phone"
                    label="Phone"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Jurisdiction-->
                <v-col cols="12" sm="12" md="3">
                  <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7
                    "
                    :display-string="
                      usersStore.profile.jurisdiction
                        ? usersStore.profile.jurisdiction.name
                        : ''
                    "
                    :label="'EPSCoR Jurisdiction'"
                  />
                  <v-autocomplete
                    v-else
                    v-model="id_jurisdiction"
                    :items="jurisdictionsStore.jurisdictionsValueList"
                    label="EPSCoR Jurisdiction *"
                    placeholder=" "
                    item-text="name"
                    item-value="id"
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.id_jurisdiction
                    "
                    @input="getInstitutionByJurisdiction()"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Institution-->
                <v-col cols="12" sm="12" md="3">
                  <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7
                    "
                    :display-string="
                      usersStore.profile.institution
                        ? usersStore.profile.institution.name
                        : ''
                    "
                    :label="'Institution'"
                  />
                  <v-autocomplete
                    v-else
                    v-model="id_institution"
                    :items="institutionsStore.institutionsValueList"
                    label="Institution *"
                    placeholder=" "
                    item-text="name"
                    item-value="id"
                    :clearable="readonly !== true"
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      id_institution === 0
                        ? rules.valid
                        : usersStore.authUser.flag_admin === 1 ||
                          readonly === true
                        ? rules.valid
                        : rules.id_institution
                    "
                    @input="getDepartmentByInstitution()"
                  />
                </v-col>

                <!--Other Institution-->
                <v-col v-if="id_institution === 0" cols="12" sm="12" md="3">
                  <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7
                    "
                    :display-string="usersStore.profile.institution_other"
                    :label="'Specify Other Institution'"
                  />
                  <v-text-field
                    v-else
                    v-model="institution_other"
                    label="Specify Other Institution *"
                    placeholder=" "
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.institution_other
                    "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Department-->
                <v-col cols="12" sm="12" md="3">
                  <!-- <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7
                    "
                    :display-string="
                      usersStore.profile.department
                        ? usersStore.profile.department.name
                        : ''
                    "
                    :label="'Department'"
                  /> -->
                  <v-autocomplete
                    v-model="id_department"
                    :items="departmentsStore.departmentsValueList"
                    label="Department *"
                    placeholder=" "
                    item-text="name"
                    item-value="id"
                    :clearable="
                      readonly !== true || usersStore.authUser.flag_admin === 1
                    "
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      id_department === 0
                        ? rules.valid
                        : usersStore.authUser.flag_admin === 1 ||
                          readonly === true
                        ? rules.valid
                        : rules.id_department
                    "
                    :readonly="
                      readonly &&
                        usersStore.authUser.flag_admin !== 1 &&
                        usersStore.authUserType !== 1 &&
                        usersStore.authUserType !== 2 &&
                        usersStore.authUserType !== 5 &&
                        usersStore.authUser.id !== usersStore.profile.id_user
                    "
                  />
                </v-col>
                <!--Other Department-->
                <v-col v-if="id_department === 0" cols="12" sm="12" md="3">
                  <text-display
                    v-if="
                      usersStore.authUser.flag_admin === 1
                        ? false
                        : readonly ||
                          usersStore.authUserType === 4 ||
                          usersStore.authUserType === 7
                    "
                    :display-string="usersStore.profile.department_other"
                    :label="'Specify Other Department'"
                  />
                  <v-text-field
                    v-else
                    v-model="department_other"
                    label="Specify Other Department *"
                    placeholder=" "
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.department_other
                    "
                  />
                </v-col>
              </v-row>

              <!--------------------START DEMOGRAPHICS-------------------->
              <v-row
                v-if="
                  usersStore.authUser.id === usersStore.profile.id_user ||
                    usersStore.authUser.flag_admin === 1
                "
                dense
              >
                <!--Gender-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="gender"
                    :items="valueListsStore.gender"
                    label="Gender *"
                    placeholder=" "
                    item-text="value"
                    item-value="value"
                    :clearable="
                      readonly !== true || usersStore.authUser.flag_admin === 1
                    "
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.gender
                    "
                    :readonly="
                      readonly &&
                        usersStore.authUser.flag_admin !== 1 &&
                        usersStore.authUser.id !== usersStore.profile.id_user
                    "
                  />
                </v-col>
                <!--Race-->
                <v-col cols="12" sm="12" md="3">
                  <span
                    class="grey--text text--darken-2 text-caption font-weight-medium pb-0"
                    >Race *</span
                  >
                  <v-radio-group column class="my-0">
                    <v-checkbox
                      v-for="(item, index) in valueListsStore.race"
                      :key="index"
                      v-model="race"
                      hide-details
                      dense
                      :label="item.value"
                      multiple
                      color="primary"
                      :value="item.value"
                      class="pr-2 py-1 my-0"
                      :readonly="
                        readonly &&
                          usersStore.authUser.flag_admin !== 1 &&
                          usersStore.authUser.id !== usersStore.profile.id_user
                      "
                      :rules="
                        usersStore.authUser.flag_admin === 1 ||
                        readonly === true
                          ? rules.valid
                          : rules.race
                      "
                      :required="
                        usersStore.authUser.flag_admin === 1 ||
                        readonly === true
                          ? false
                          : true
                      "
                    />
                  </v-radio-group>
                </v-col>
                <!--Ethnicity-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="ethnicity"
                    :items="valueListsStore.ethnicity"
                    label="Ethnicity *"
                    placeholder=" "
                    item-text="value"
                    item-value="value"
                    :clearable="
                      readonly !== true || usersStore.authUser.flag_admin === 1
                    "
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.ethnicity
                    "
                    :readonly="
                      readonly &&
                        usersStore.authUser.flag_admin !== 1 &&
                        usersStore.authUser.id !== usersStore.profile.id_user
                    "
                  />
                </v-col>

                <!--Disability-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="disability"
                    :items="valueListsStore.disability"
                    label="Disability *"
                    placeholder=" "
                    item-text="value"
                    item-value="value"
                    :clearable="
                      readonly !== true || usersStore.authUser.flag_admin === 1
                    "
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.disability
                    "
                    :readonly="
                      readonly &&
                        usersStore.authUser.flag_admin !== 1 &&
                        usersStore.authUser.id !== usersStore.profile.id_user
                    "
                  />
                </v-col>
              </v-row>
              <!--------------------END DEMOGRAPHICS-------------------->

              <v-row
                v-if="
                  classification === 'Graduate Student' ||
                    classification === 'Undergraduate'
                "
                dense
              >
                <!--Degree-->
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                    v-model="degree"
                    :items="valueListsStore.degree"
                    label="Degree being pursued *"
                    placeholder=" "
                    item-text="value"
                    item-value="value"
                    :clearable="
                      readonly !== true || usersStore.authUser.flag_admin === 1
                    "
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.degree
                    "
                    :readonly="
                      readonly &&
                        usersStore.authUser.flag_admin !== 1 &&
                        usersStore.authUserType !== 1 &&
                        usersStore.authUserType !== 2 &&
                        usersStore.authUserType !== 5
                    "
                  />
                </v-col>
                <!--Degree Earned-->
                <v-col cols="12" sm="12" md="2">
                  <v-autocomplete
                    v-model="degree_earned"
                    :items="valueListsStore.yesNo"
                    label="Has degree been earned? *"
                    placeholder=" "
                    item-text="value"
                    item-value="value"
                    :clearable="
                      readonly !== true || usersStore.authUser.flag_admin === 1
                    "
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.degree_earned
                    "
                    :readonly="
                      readonly &&
                        usersStore.authUser.flag_admin !== 1 &&
                        usersStore.authUserType !== 1 &&
                        usersStore.authUserType !== 2 &&
                        usersStore.authUserType !== 5
                    "
                    @input="setDegreeDates()"
                  />
                </v-col>
                <!--Degree Month-->
                <v-col v-if="degree_earned === 'Yes'" cols="12" sm="12" md="2">
                  <v-autocomplete
                    v-model="degree_month"
                    :items="valueListsStore.monthAbbreviations"
                    item-text="value"
                    item-value="value"
                    label="Month *"
                    placeholder=" "
                    :clearable="
                      readonly !== true || usersStore.authUser.flag_admin === 1
                    "
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.degree_month
                    "
                    :readonly="
                      readonly &&
                        usersStore.authUser.flag_admin !== 1 &&
                        usersStore.authUserType !== 1 &&
                        usersStore.authUserType !== 2 &&
                        usersStore.authUserType !== 5
                    "
                  />
                </v-col>
                <!--Degree Year-->
                <v-col v-if="degree_earned === 'Yes'" cols="12" sm="12" md="2">
                  <v-autocomplete
                    v-model="degree_year"
                    :items="valueListsStore.projectYearList"
                    item-text="value"
                    item-value="value"
                    label="Year *"
                    placeholder=" "
                    :clearable="
                      readonly !== true || usersStore.authUser.flag_admin === 1
                    "
                    clear-icon="fal fa-times-circle"
                    :required="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? false
                        : true
                    "
                    :rules="
                      usersStore.authUser.flag_admin === 1 || readonly === true
                        ? rules.valid
                        : rules.degree_year
                    "
                    :readonly="
                      readonly &&
                        usersStore.authUser.flag_admin !== 1 &&
                        usersStore.authUserType !== 1 &&
                        usersStore.authUserType !== 2 &&
                        usersStore.authUserType !== 5
                    "
                  />
                </v-col>
              </v-row>

              <v-row v-if="usersStore.authUser.flag_admin === 1" dense>
                <v-divider />
              </v-row>

              <v-row v-if="usersStore.authUser.flag_admin === 1" dense>
                <!--Flag profile checked-->
                <v-col cols="12" sm="12" md="2">
                  <v-autocomplete
                    v-model="flag_check"
                    :items="valueListsStore.yesNo"
                    label="Profile Checked"
                    placeholder=" "
                    item-text="value"
                    item-value="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>

                <v-col cols="12" sm="12" md="3">
                  <text-display
                    :display-string="$_formatTimestamp(date_profile_check)"
                    :label="'Date Profile Checked'"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn block rounded color="primary" @click="submitEntry()">
            <v-icon small left> fal fa-check </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "usersStore/getProfileField",
  mutationType: "usersStore/updateProfileField",
});

export default {
  name: "ProfileEntry",
  components: {
    TextDisplay,
  },
  mixins: [mixin],
  /*1 = Profile Check Screen, 2 = Profile Edit*, 3 = User Detail*/
  props: {
    section: Number,
    profileID: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      rules: {
        title: [(v) => !!v || "Required"],
        title_other: [(v) => !!v || "Required"],
        classification: [(v) => !!v || "Required"],
        classification_other: [(v) => !!v || "Required"],
        id_jurisdiction: [(v) => !!v || "Required"],
        id_institution: [(v) => !!v || "Required"],
        institution_other: [(v) => !!v || "Required"],
        id_department: [(v) => !!v || "Required"],
        department_other: [(v) => !!v || "Required"],
        gender: [(v) => !!v || "Required"],
        ethnicity: [(v) => !!v || "Required"],
        disability: [(v) => !!v || "Required"],
        degree: [(v) => !!v || "Required"],
        degree_earned: [(v) => !!v || "Required"],
        degree_month: [(v) => !!v || "Required"],
        degree_year: [(v) => !!v || "Required"],
        race: [
          (v) => !!v || "Required",
          (v) => (v && v.length > 0) || "Required",
        ],
        valid: [1 === 1],
      },
    };
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
      progressStore: (state) => state.progressStore,
      valueListsStore: (state) => state.valueListsStore,
      jurisdictionsStore: (state) => state.jurisdictionsStore,
      institutionsStore: (state) => state.institutionsStore,
      departmentsStore: (state) => state.departmentsStore,
      participationStore: (state) => state.participationStore,
      projectsStore: (state) => state.projectsStore,
    }),
    ...mapFields([
      "valid",
      "show",
      "mode",

      "id",
      "id_project",
      "id_user",
      "id_institution",
      "id_department",
      "id_jurisdiction",
      "institution_other",
      "department_other",
      "orcid_id",
      "title",
      "title_other",
      "classification",
      "classification_other",
      "gender",
      "race",
      "ethnicity",
      "disability",
      "phone",
      "degree",
      "degree_earned",
      "degree_month",
      "degree_year",
      "jurisdiction",
      "department",
      "institution",
      "flag_user_type",
      "flag_check",
      "date_profile_check",

      "readonly",
      "flag_status",
    ]),
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      if (this.section === 1) {
        let activeUser = this.$store.getters["usersStore/getActiveUser"];
        let activeUserType = this.$store.getters[
          "usersStore/getActiveUserType"
        ];
        let activeProject = this.$store.getters["usersStore/getActiveProject"];
        let profileID = activeProject.pivot.id;
        for (let i = 0; i < activeUser.participation_active.length; i++) {
          if (
            activeUser.participation_active[i].id_project ===
              activeProject.id &&
            activeUser.participation_active[i].flag_user_type === activeUserType
          ) {
            profileID = activeUser.participation_active[i].id;
          }
        }
        this.openEntry(profileID);
      }
    },

    async openEntry(id) {
      let profile = {
        id: id,
      };
      await this.$store.dispatch("usersStore/getProfile", profile);
      let userProfile = await this.$store.getters["usersStore/getProfile"];
      await this.$store.dispatch("projectsStore/read", userProfile.id_project);
      let project = await this.$store.getters["projectsStore/getProject"];
      let activeUser = await this.$store.getters["usersStore/getActiveUser"];
      let data = {
        id_project: userProfile.id_project,
        id_jurisdiction: userProfile.id_jurisdiction,
        id_institution: userProfile.id_institution,
      };
      let yearStart = this.$_formatDateYear(project.date_start);
      let year = {
        yearStart: yearStart,
      };
      await this.$store.dispatch("valueListsStore/setProjectYearList", year);
      await this.$store.dispatch("jurisdictionsStore/byProject", data);
      await this.$store.dispatch("institutionsStore/byProject", data);
      await this.$store.dispatch("departmentsStore/byProject", data);
      this.show = true;
    },

    async submitEntry() {
      if (this.$refs.entryForm.validate()) {
        if (this.title !== "Other") {
          this.title_other = "";
        }
        if (this.classification !== "Other") {
          this.classification_other = "";
        }
        if (this.id_institution !== 0) {
          this.institution_other = "";
        }
        if (this.id_department !== 0) {
          this.department_other = "";
        }
        if (
          this.classification !== "Graduate Student" &&
          this.classification !== "Undergraduate"
        ) {
          this.degree = "";
        }
        if (this.degree_earned === "No") {
          this.degree_month = "";
          this.degree_year = "";
        }
        let user = this.$store.getters["usersStore/getAuthUser"];
        if (user.flag_admin !== 1) {
          this.flag_check = 1;
        }
        let profile = await this.$store.getters["usersStore/getProfile"];
        await this.$store.dispatch("usersStore/updateProfile", profile);

        await this.$store.dispatch(
          "participationStore/updateParticipationList",
          this.id
        );
        await this.$store.dispatch("usersStore/readProfile", profile);
        const activeProject = this.$store.getters[
          "usersStore/getActiveProject"
        ];
        window.localStorage.setItem(
          "activeProject",
          JSON.stringify(activeProject)
        );
        let activeUser = this.$store.getters["usersStore/getActiveUser"];
        window.localStorage.setItem("activeUser", JSON.stringify(activeUser));

        this.show = false;
        if (this.section === 1) {
          this.$router.push({ name: "dashboard" });
        }
      }
    },

    async getInstitutionByJurisdiction() {
      let project = this.$store.getters["projectsStore/getProject"];

      // if (!project) {
      //   const userProfile = await this.$store.getters["usersStore/getProfile"];
      //   await this.$store.getters[
      //     ("projectsStore/read", userProfile.id_project)
      //   ];
      //   project = this.$store.getters["usersStore/getActiveProject"];
      // }

      let data = {
        id_project: project.id,
        id_jurisdiction: this.id_jurisdiction,
      };
      await this.$store.dispatch("institutionsStore/byProject", data);
      this.id_institution = "";
      this.institution_other = "";
      this.id_department = "";
      this.department_other = "";
    },

    async getDepartmentByInstitution() {
      let project = this.$store.getters["projectsStore/getProject"];

      // if (!project) {
      //   const userProfile = await this.$store.getters["usersStore/getProfile"];
      //   await this.$store.getters[
      //     ("projectsStore/read", userProfile.id_project)
      //   ];
      //   project = this.$store.getters["usersStore/getActiveProject"];
      // }

      let data = {
        id_project: project.id,
        id_institution: this.id_institution,
      };
      await this.$store.dispatch("departmentsStore/byProject", data);
      this.id_department = "";
      this.department_other = "";
    },

    setDegreeDates() {
      if (this.degree_earned !== "Yes") {
        this.degree_month = "";
        this.degree_year = "";
      }
    },

    cancelEntry() {
      this.show = false;
      if (this.section === 1) {
        this.$router.push({ name: "logout" });
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
